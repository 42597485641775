@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@300;400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  --ck-border-radius: 8px;
  --ck-color-focus-border: #1f82ff;
  --ck-inner-shadow: none;
  --ck-color-hover-border: hsl(43, 100%, 68%);
  --ck-color-base-border: #d9d9d9;
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
}

::-webkit-scrollbar {
  padding: 0px 4px;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 99px;
}

.ant-layout {
  background-color: white;
}

.list ul li::marker {
  color: #1f82ff;
}

.list ul {
  padding-left: 15px;
}

.list ul li {
  color: #0f2b61;
  margin-bottom: 8px;
}

.editor .ck.ck-editor__main > .ck-editor__editable:hover {
  border-color: #1f82ff !important;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by {
  display: none;
}

.editor_error .ck.ck-editor__main > .ck-editor__editable {
  border-color: #ff4d4f !important;
}

.ant-popover .ant-popover-arrow {
  display: none;
}

.swiper_img {
  border-radius: 50%;
  object-fit: cover;
}

.ant-tabs .ant-tabs-tab-btn {
  color: #0f2b61 !important;
}

.ant-table .ant-table-cell {
  color: #0f2b61 !important;
}

.ant-form label {
  color: #0f2b61 !important;
}

textarea {
  color: #0f2b61 !important;
}

input {
  color: #0f2b61 !important;
}

.ant-table-filter-dropdown .ant-dropdown-menu-title-content span {
  color: #0f2b61 !important;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button {
  height: unset;
}
.ant-select-single .ant-select-selector {
  color: #0f2b61 !important;
}
.ant-select-dropdown .ant-select-item {
  color: #0f2b61 !important;
}

p {
  color: #0f2b61 !important;
}

li {
  color: #0f2b61;
}

@media screen and (min-width: 1440px) {
  .coverflow.padding {
    padding-top: 220px;
  }
  .space_top {
    top: -220px;
  }
}

@media screen and (min-width: 1056px) and (max-width: 1440px) {
  .coverflow.padding {
    padding-top: 250px;
  }
  .space_top {
    top: -250px;
  }
}

@media screen and (min-width: 668px) and (max-width: 1056px) {
  .coverflow.padding {
    padding-top: 320px;
  }
  .space_top {
    top: -320px;
  }
}
.editorAll {
  .ck.ck-reset_all {
    display: none !important;
  }
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-radius: 10px !important;
  }
}
