@media screen and (max-width: 1350px) {
  .wrap {
    white-space: wrap;
  }
}

@media screen and (min-width: 1350px) {
  .wrap {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1250px) {
  .flex-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1100px) {
  .flex-wrap-footer {
    flex-wrap: wrap;
  }
}
